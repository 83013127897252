<template>
  <div>
    <div class="breadcrumb-usuario">
        <div class="container">
            <div v-if="credenciales.hasOwnProperty('dni')">
                <i class="fa fa-user-circle"></i> {{credenciales.descripcion}}
                <div v-if="credenciales.simula>0" class="alerta">Simulando fecha: {{fechaSimula}}</div>
                &nbsp;<button class="btn btn-primary" @click.prevent="salir(true)">Salir</button>
            </div>
            <div v-else>
                <i class="fa fa-user-circle"></i> Desconectado
            </div>
        </div>
    </div>
    <!-- Indicador de carga -->
    <div v-if="cargando>0">
        <h3 style="display: block; text-align: center; margin-bottom: 30px; margin-top: 50px;">Autenticando...</h3>
        <div class="spinner"></div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'

export default {
    name: 'sesion',
    data() {
        return {
            credenciales: {},
            cargando: 0,
        }
    },
     mounted: function () {
      //Intentamos recuperar la sesión que estuviera guardada
      this.renovar(sessionStorage.getItem(this.$nombreToken));
    },
    computed: {
      fechaSimula: function() {
        if (this.credenciales.simula == 0) {
          return '';
        }  
        else {
          var fecha = new Date(this.credenciales.simula * 1000);
          return fecha.getDate() + "/" + (fecha.getMonth()+1) + "/" + fecha.getFullYear() + " " + fecha.getHours() + ":" + fecha.getMinutes();
        }
      },
    },   
    methods: {
      entrar(dni, clave) {
        this.salir();
        if (dni != '' && clave != '') {
          this.cargando++;
          this.$apollo.mutate({
            mutation: gql`mutation($dni: String!, $password: String!) {
              tokenAuth(dni: $dni, password: $password) {
                token
                clave
              }
            }`,
            variables: {
              dni: dni,
              password: clave,
            },
          }).then((data) => {
            // Es posible que se haya generado una nueva clave
            if (data.data.tokenAuth.clave) {
              this.$emit('aviso-clave', data.data.tokenAuth.clave);
            }
            // Llamo a renovar para obtener los datos de payload  
            this.renovar(data.data.tokenAuth.token);
          }).catch((error) => {
            if (error.message.includes('credentials')) {
              this.$emit('aviso-fallo', dni);
            }
            else {
              this.$emit('aviso', 'Ha habido un error.');
            }
          }).finally(() => {
            this.cargando--;
          });
        }
        else {
          this.$emit('aviso', 'Debe introducir documento y clave.');
        }
        this.dni = '';
        this.clave = '';
      },
      renovar(token) {
        if (token && token != '') {
          this.cargando++;
          this.$apollo.mutate({
            mutation: gql`mutation($token: String!) {
              verifyToken(token: $token) {
                payload
              }
            }`,
            variables: {
              token: token,
            },
          }).then((data) => {
            this.credenciales = data.data.verifyToken.payload;
            this.$emit('autenticado', {
              dni: data.data.verifyToken.payload.dni,
              sello: data.data.verifyToken.payload.simula,
            });
            sessionStorage.setItem(this.$nombreToken, token);
          }).catch((error) => {
            this.salir();
            if (error.message.includes('expired')) {
              this.$emit('aviso', 'La sesión ha caducado.');
            }
            else {
              this.$emit('aviso', 'Ha habido un error.');
            }
          }).finally(() => {
            this.cargando--;
          });
        }
        else {
          this.salir();
        }
      },
      salir(manual=false) {
        this.credenciales = {};
        sessionStorage.removeItem(this.$nombreToken);
        if (manual) {
          this.$emit('logout-manual');
        }
        else {
          this.$emit('logout');
        }
      },
    }
}
</script>

<style scoped>
  .alerta {
    background-color: crimson;
    color: white;
    text-align: center;
  }
</style>