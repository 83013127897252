// Captura de errores no controlados

function errorGeneral() {
    // Mostramos el cuadro "error-general" que estaba oculto
    document.getElementById('error-general').style.display = 'block';
    // Ocultamos todos los indicadores de espera para que no parezca
    // que seguimos haciendo algo
    var ruedas = document.getElementsByClassName('spinner');
    for (var i=0; i<ruedas.length; i++) {
      ruedas.item(i).style.display = 'none';
    }
  }

export {errorGeneral}
