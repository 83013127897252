<template>
    <div>
        <div v-if="dni && dni===alumno.dni">
            <!-- Inicio Titulo Contenido -->
            <div class="single_header">
                <h2 class="">
                    <span class="strong_underline">
                        Mis expedientes
                    </span>
                </h2>
            </div>
            <!-- Fin Titulo Contenido -->

            <!-- Inicio contenido -->

            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                <div v-for="(e, indice) in alumno.expediente" :key="e.id" class="panel panel-default">
                    <div class="panel-heading" style="background-color: #d9edf7;" role="tab" :id="'cabecera'+indice" data-toggle="collapse" data-parent="#accordion" :href="'#cuerpo'+indice" aria-expanded="true" :aria-controls="'cuerpo'+indice">
                        <h3 class="panel-title">
                            <i class="fa fa-th-large"></i> {{e.convocatoria.descripcion}} ({{e.convocatoria.ano.anoacad}}) - 
                            Núm. inscripción: {{e.numeroinscripcion}}
                        </h3>
                    </div>
                    <div :id="'cuerpo'+indice" class="panel-collapse collapse"  :class="{ in: indice==0 }" role="tabpanel" :aria-labelledby="'cabecera'+indice">
                        <div class="panel-body">
                            <div v-if="e.convocatoria.tipo.endsWith('ordinaria')">
                                <span class="medium_underline"><i class="fa fa-home"></i> Centro: <b>{{e.centro==null ? '': e.centro.nombre}}</b></span>
                                <div v-if="e.tipoAcceso === 'B'">
                                    <span class="medium_underline"><i class="fa fa-wpforms"></i> Nota media de expediente de bachillerato: <b>{{e.mediaexp}}</b></span>
                                </div>                            
                            </div>
                            <!-- Descarga tarjeta -->
                            <div class="col-xs-12 opcionessolicitud">
                                <div class="col-sm-6 col-xs-12 col-md-4">
                                    <div class="enlaceopcion">
                                        <div v-if="e.urlTarjeta">
                                            <a :href="urlDescarga(e.urlTarjeta)" style="display: block;" target="_blank">
                                                <div class="interes_icon">
                                                    <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                                                </div>
                                                <div class="interes_text">Tarjeta calificaciones</div>
                                            </a>
                                        </div>
                                        <div v-else>
                                            <div class="opciondisabled">
                                                <div class="interes_icon">
                                                    <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                                                </div>
                                                <div class="interes_text">Tarjeta no disponible</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Inicio Consulta calificaciones-->
                            <div class="row" v-for="(v, indicevia) in e.eleccionviaacceso" :key="v.id">

                                <div class="subtitulo_contenido">
                                    <h4 class="subtitulo">
                                        <span class="medium_underline"><i class="fa fa-list-alt"></i> 
                                        Calificaciones {{e.convocatoria.tipo == 'Mayores 25' ? v.viaacceso.nombre :''}}</span>
                                    </h4>
                                </div>
                                <div class="col-sm-12 col-md-10 col-md-offset-1">
                                    <!-- Se supone en todo momento una sola solicitud máximo -->
                                    <div v-if="e.solicitud.length>0 && !solicitudes" class="row publico_info_5">
                                        <i class="fa fa-database"></i>
                                        Solicitud de {{tituloSolicitud(e)}} registrada con fecha {{formatoFecha(e.solicitud[0].fecha)}}: 
                                        <span v-if="e.plazoSolicitud">
                                            <button type="button" class="btn btn-success" @click.prevent="iniciaEdicion" :disabled="solicitudes"><i class="fa fa-pencil"></i> Modificar</button>
                                             &nbsp;
                                            <button type="button" class="btn btn-danger" @click.prevent="cancelaSolicitud" :disabled="solicitudes"><i class="fa fa-times"></i> Borrar</button>
                                        </span>
                                        <span v-else>
                                            <span v-if="e.solicitud[0].resuelta" class="interesicon">
                                                Resuelta 
                                                <a v-if="e.solicitud[0].urlNotificacion" class="btn btn-primary" :disabled="solicitudes" target="_blank" :href="urlDescarga(e.solicitud[0].urlNotificacion)" >
                                                    <i class="fa fa-download"></i> Descargar notificación
                                                </a>
                                                <span v-else class="opciondisabled"> 
                                                    (notificación no disponible)
                                                </span>
                                            </span>
                                            <span v-else>
                                                Pendiente resolución
                                            </span>
                                        </span>
                                    </div>
                                    <div v-if="e.abiertaSolicitud && !e.plazoSolicitud && e.solicitud.length === 0 && e.urlInforme" class="row publico_info_3" style="color: green;">
                                        <i class="fa fa-file"></i>
                                        No has presentado solicitud de revisión.
                                        <a class="btn btn-primary" target="_blank" :href="urlDescarga(e.urlInforme)" >
                                          <i class="fa fa-download"></i> Obtener informe de no revisión
                                        </a>
                                    </div>
                                    <div :style="{display: e.plazoSolicitud && solicitudes ? 'block': 'none'}" class="row cuadro-solicitud">
                                        <span v-if="solicitudes && solicitudes.size>0">
                                            <i class="fa fa-edit"></i> Estás modificando tu solicitud de {{tituloSolicitud(e)}}. Ahora incluye {{solicitudes.size}} {{solicitudes.size==1? 'examen':'exámenes'}}: 
                                        </span>
                                        <span v-else>
                                            <i class="fa fa-trash"></i> Estás anulando tu solicitud de {{tituloSolicitud(e)}}: 
                                        </span>
                                        <button @click.prevent="confirmar" type="button" class="btn btn-success"><i class="fa fa-check"></i> Confirmar</button>
                                         &nbsp;
                                        <button @click.prevent="limpiar" type="button" class="btn btn-danger"><i class="fa fa-close"></i> Cancelar</button>
                                    </div>
                                    <!-- Ventana modal de confirmación -->
                                    <div v-if="e.plazoSolicitud && pongo.length+quito.length+dejo.length > 0">
                                        <div id="confirma-solicitud" class="modal show" tabindex="-1" role="dialog">
                                            <div class="modal-dialog">
                                                <div class="modal-content">
                                                    <div id="cabecera-confirmacion" class="modal-header">
                                                        <h4 class="modal-title">
                                                            <span v-if="solicitudes && solicitudes.size>0">
                                                                Modificación de solicitud de {{tituloSolicitud(e)}} 
                                                            </span>
                                                            <span v-else>
                                                                Anulación de solicitud de {{tituloSolicitud(e)}}
                                                            </span>
                                                        </h4>
                                                    </div>
                                                    <div id="cuerpo-confirmacion" class="modal-body text-center">
                                                        <div v-if="pongo.length>0">
                                                            Estás <b>añadiendo</b> a tu solicitud:
                                                            <ul class="sol-ul-add">
                                                                <li v-for="ea in pongo" :key="ea.id">
                                                                    {{ea.asignatura.nombrecorto}}
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div v-if="quito.length>0">
                                                            Estás <b>quitando</b> de tu solicitud:
                                                            <ul class="sol-ul-delete">
                                                                <li v-for="ea in quito" :key="ea.id" style="text-decoration:line-through;">
                                                                    {{ea.asignatura.nombrecorto}}
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div v-if="dejo.length>0">
                                                            <b>Mantienes</b> en tu solicitud:
                                                            <ul ul class="sol-ul-equal">
                                                                <li v-for="ea in dejo" :key="ea.id">
                                                                    {{ea.asignatura.nombrecorto}}
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div class="publico_documentacion">
                                                            <span v-if="solicitudes && solicitudes.size>0">
                                                                <i class="fa fa-envelope"></i> Al confirmar recibirás un email como justificante de la solicitud de {{tituloSolicitud(e)}} 
                                                            </span>
                                                            <span v-else>
                                                                <i class="fa fa-envelope"></i> Al confirmar recibirás un email como justificante de la anulación de la solicitud de {{tituloSolicitud(e)}}
                                                            </span>
                                                        </div>
                                                        <div v-if="errorSolicitud" class="publico_info_error">
                                                            {{errorSolicitud}}
                                                        </div>
                                                    </div>
                                                    <div class="modal-footer">
                                                        <button type="button" class="btn btn-success" @click="reconfirmar(e)" :disabled="ejecutando>0">
                                                            <div v-if="ejecutando==0"><i class="fa fa-check"></i> Confirmar</div>
                                                            <div v-else class="intermitente"><i class="fa fa-check"></i> Registrando...</div>
                                                        </button> &nbsp;

                                                        <button type="button" class="btn btn-danger" data-dismiss="modal" @click="limpiar">
                                                            <i class="fa fa-close"></i> Cancelar
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Fin ventana modal -->
                                    <div v-if="e.plazoSolicitud && e.solicitud.length==0" :style="{display: ayudaVisible ? 'block': 'none'}" class="row publico_info_1">
                                            <i class="fa fa-info-circle"></i> Abierto plazo de {{tituloSolicitud(e)}}. Selecciona exámenes si deseas solicitar.
                                    </div>
                                    <!-- La primera parte (orden=1) solo la mostramos en la primera instancia de vía (indicevia=0), porque se supone que es la parte común -->
                                    <div v-for="p in v.eleccionparte" :key="p.id" class="row" v-show="indicevia === 0 || p.parte.orden !== 1">
                                        <div v-if="p.eleccionasignatura.length > 0">
                                            <table class="table table-hover tabla-asignaturas">
                                                <thead>
                                                    <tr class="info">
                                                        <th style="width: 80%;" colspan="2" id="examencal">{{p.parte.descripcion}}</th>
                                                        <th style="width: 20%; text-align: center;">{{e.abiertaSolicitud? 'Solicitud ' + tituloSolicitud(e): ''}} 
                                                            <a v-if="e.abiertaSolicitud" href="" data-toggle="modal" :data-target="'#leyenda-iconos-' + tituloSolicitud(e)"><i class="fa fa-question-circle" alt="Ayuda sobre la solicitud" title="Ayuda sobre la solicitud"></i></a>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="a in p.eleccionasignatura" :key="a.id" 
                                                    @click.prevent="cambiaSolicitud(e, a)" :class="describeSolicitudes.has(a.examen)? describeSolicitudes.get(a.examen).clasetr: ''" >
                                                        <td headers="examencal">{{e.convocatoria.tipo == 'Mayores 40' && a.asignatura.nombrecorto != 'Entrevista' ? a.asignatura.nombre : a.asignatura.nombrecorto}}</td>
                                                        <td headers="examencal" style="text-align: right;">
                                                            <span v-if="a.examen.calificacionAnterior && a.examen.calificacion && a.examen.calificacionAnterior != a.examen.calificacion" class="nota-antigua">
                                                                {{a.examen.calificacionAnterior}}
                                                            </span>
                                                            {{a.examen.calificacion}}
                                                        </td>
                                                        <td v-if="e.abiertaSolicitud" style="text-align: center;">
                                                            <a href="" class="fa" 
                                                            :class="describeSolicitudes.has(a.examen)? describeSolicitudes.get(a.examen).clase: ''" 
                                                            :alt="describeSolicitudes.has(a.examen)? describeSolicitudes.get(a.examen).literal: ''"
                                                            :title="describeSolicitudes.has(a.examen)? describeSolicitudes.get(a.examen).literal: ''"></a>
                                                        </td>
                                                        <td v-else></td>
                                                    </tr>
                                                    <tr class="active" v-if="p.parte.calculable">
                                                        <td headers="examencal">Calificación {{p.parte.descripcion}}:</td>
                                                        <td headers="examencal" style="text-align: right;">{{p.calificacion}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table v-show="!e.convocatoria.tipo.startsWith('Mayores 4') &&  ultimaCalculable(v, p)" class="table table-hover">
                                                <tbody>
                                                    <tr v-if="e.convocatoria.tipo == 'Mayores 25'" class="active">
                                                        <th>CALIFICACIÓN FINAL (Rama {{v.viaacceso.nombre}})
                                                        </th>
                                                        <th style="text-align: right;">{{v.calificacionAcceso}}</th>
                                                    </tr>
                                                    <tr v-else class="active">
                                                        <th>CALIFICACIÓN FINAL 
                                                            <br><span style="font-size: small;">
                                                                ({{e.modalidad.pesomediabachillerato}} X nota media bachillerato) + 
                                                                ({{1 - e.modalidad.pesomediabachillerato}} X calificaci&oacute;n {{p.parte.descripcion}})</span>
                                                        </th>
                                                        <th style="text-align: right;">{{v.calificacionAcceso}}</th>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-for="r in e.recuadro" :key="r.id" class="publico_info_3">
                                <span v-html="r.texto" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <!-- Fin contenido -->

        </div>

        <!-- Indicador de carga -->
        <div ref="carga" v-if="$apollo.loading">
            <h3 style="display: block; text-align: center; margin-bottom: 30px; margin-top: 50px;">Cargando expedientes...</h3>
            <div class="spinner"></div>
        </div>
    </div>
</template>

<script>
import gql from 'graphql-tag'

export default {
    name: 'alumno',
    props: {
        dni: String,
        sello: Number,
    },
    data() {
        return {
            alumno: Object,
            solicitudes: null, // OJO: Set no soporta reactividad en Vue 2
            almendruco: 0,  // El truco para hacerlo reactivo!
            pongo: [],
            quito: [],
            dejo: [],
            errorSolicitud: null,
            ejecutando: 0,  // Indicador de carga para la mutation
            ayudaVisible: true,
        }
    },
    apollo: {
        alumno: {
            // Solo se puede hacer query del mismo DNI y sello de tiempo del usuario autenticado
            // Eso fuerza a que la query sea reactiva, es decir,
            // que se ejecute de nuevo si se hace logout y después 
            // entra otro usuario sin haber recargado la página
            // o se entra simulando el mismo usuario pero con otra fecha
            query: gql`query($dni:String!,$sello:Int!) {
                alumno(dni:$dni,sello:$sello) {
                    dni
                    nombre
                    apellido1
                    apellido2
                    expediente {
                        id
                        convocatoria {
                            tipo
                            descripcion
                                ano {
                                    anoacad
                                }
                        }
                        modalidad {
                            pesomediabachillerato
                        }
                        centro {
                            nombre
                        }
                        numeroinscripcion
                        mediaexp
                        tipoAcceso
                        abiertaSolicitud
                        plazoSolicitud
                        urlTarjeta
                        urlInforme
                        recuadro {
                            id
                            texto
                        }
                        solicitud {
                            id
                            resuelta
                            fecha
                            urlNotificacion
                            lineasolicitud {
                                id
                                examen {
                                    id
                                }
                                asignatura {
                                    nombre
                                }
                            }
                        }
                        eleccionviaacceso {
                            id
                            calificacionAcceso
                            viaacceso {
                                nombre
                            }
                            eleccionparte {
                                id
                                parte {
                                    descripcion
                                    calculable
                                    orden
                                }
                                calificacion
                                eleccionasignatura {
                                    id
                                    asignatura {
                                        nombre
                                        nombrecorto
                                    }
                                    examen {
                                        id
                                        calificacion
                                        calificacionAnterior
                                        estado
                                    }
                                }
                            }
                        }
                    }
                }
            }`,
            variables() {
                // Lo hago reactivo por si cambiase el usuario sin recargar
                return {
                    dni: this.dni,
                    sello: this.sello
                }
            },
            fetchPolicy: 'network-only',  // Evitar caché por si sale y vuelve a entrar en simulación
            skip() {
                return !this.dni;
            },
            error() {
                this.$emit('aviso', 'Ha habido un error. Pruebe más tarde.');
                // Por alguna razón (bug?) el spinner se quedaba visible en caso de error (aunque loading=false)
                // La única manera de quitarlo ha sido bajar hasta la propiedad display de javascript
                this.$refs.carga.style.display = "none";
            },
        },
    },
    computed: {
        solicitudActual() {
            this.almendruco;  // Referencia forzada para que se refresque al tocar esta variable
            const actual = new Set();
            for (const e of this.alumno.expediente) {
                if (e.plazoSolicitud) {
                    if (e.solicitud.length > 0) {
                        for (const s of e.solicitud[0].lineasolicitud) {
                            // Para cada línea buscamos la eleccionasignatura correspondiente
                            for (const v of e.eleccionviaacceso) {
                                for (const p of v.eleccionparte) {
                                    for (const a of p.eleccionasignatura) {
                                        if (a.examen.id == s.examen.id) {
                                            actual.add(a);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            return actual;
        },
        describeSolicitudes() {
            this.almendruco;  // Referencia forzada
            const m = new Map();
            for (const e of this.alumno.expediente) {
                var titulosol = this.tituloSolicitud(e);
                for (const v of e.eleccionviaacceso) {
                    for (const p of v.eleccionparte) {
                        for (const a of p.eleccionasignatura) {
                            var comun = '';
                            if (this.solicitudActual && this.solicitudes && 
                                    (this.solicitudActual.has(a) || this.solicitudes.has(a))) {
                                comun = ' solicitud-modificada ';
                            }
                            if (this.solicitudes && this.solicitudes.has(a))
                                m.set(a.examen, { 
                                    clasetr: 'fila-modificada',
                                    clase: 'fa-check-square solicitud-pendiente' + comun, 
                                    literal: 'Solicita ' + titulosol
                                });
                            else if (a.examen.estado == null)
                                if (e.plazoSolicitud) {
                                    m.set(a.examen, { 
                                        clasetr: '',
                                        clase: 'fa-square-o solicitud-inactiva' + comun, 
                                        literal: 'No solicita ' + titulosol
                                    });
                                } else {
                                    m.set(a.examen, {
                                        clasetr: '',
                                        clase: 'fa-square-o solicitud-inactiva nocursor' + comun, 
                                        literal: 'No solicita ' + titulosol
                                    });
                                }
                            else if (a.examen.estado == 'pendiente' || a.examen.estado == 'solicitada') {
                                if (this.solicitudes && !this.solicitudes.has(a))
                                    m.set(a.examen, { 
                                        clasetr: 'fila-eliminada',
                                        clase: 'fa-times-square-o solicitud-pendiente' + comun, 
                                        literal: 'Anula ' + titulosol
                                    });
                                else
                                    if (e.plazoSolicitud) {
                                        m.set(a.examen, { 
                                            clasetr: '',
                                            clase: 'fa-clock-square-o solicitud-pendiente' + comun, 
                                            literal: 'Pendiente resolución de ' + titulosol
                                        });
                                    } else {
                                        m.set(a.examen, { 
                                            clasetr: '',
                                            clase: 'fa-clock-square-o solicitud-pendiente nocursor' + comun, 
                                            literal: 'Pendiente resolución de ' + titulosol
                                        });
                                    }
                            }
                            else if (a.examen.estado == 'revisada' ||
                                     a.examen.estado == 'aceptada' ||
                                     a.examen.estado == 'rechazada')
                                m.set(a.examen, {
                                    clasetr: '',
                                    clase: 'fa-calendar-check-o solicitud-resuelta',
                                    literal: 'Resuelta ' + titulosol
                                });
                            else
                                m.set(a.examen, { 
                                    clasetr: '',
                                    clase: 'solicitud-invalida', 
                                    literal: ''
                                });
                        }
                    }
                }
            }
            return m;
        },
    },
    methods: {
        ultimaCalculable: function(via, eleccionparte) {
            //Resuelve si la parte indicada es la última que es calculable dentro de ese expediente
            var ultima = null;
            for (var i=0; i<via.eleccionparte.length; i++) {
                if (via.eleccionparte[i].parte.calculable && via.eleccionparte[i].eleccionasignatura.length > 0) {
                    ultima = via.eleccionparte[i];
                }
            }
            return ultima !== null && ultima.id === eleccionparte.id;
        },
        urlDescarga: function(url) {
            return process.env.VUE_APP_URL_BASE_DESCARGA + url;
        },
        tituloSolicitud: function(expediente) {
            return expediente.convocatoria.tipo.endsWith('ordinaria')? 'revisión' : 'reclamación';
        },
        formatoFecha: function(iso) {
            // GraphQL nos da formato ISO. Primero comvertimos a Date
            const fecha = new Date(iso);
            return fecha.getDate() + "/" + (fecha.getMonth()+1) + "/" + fecha.getFullYear() + " " + 
                fecha.getHours().toString().padStart(2,'0') + ":" + fecha.getMinutes().toString().padStart(2,'0');
        },
        iniciaEdicion: function() {
            // Clonamos la solicitud actual para editarla
            this.solicitudes = new Set(this.solicitudActual);
            this.pongo.length = 0;
            this.quito.length = 0;
            this.dejo.length = 0;
            if (!this.solicitudes) {
                this.ayudaVisible = true;
            }
            // Forzamos remapeo de las asignaturas
            this.almendruco++;
        },
        cancelaSolicitud: function() {
            this.iniciaEdicion();
            this.solicitudes.clear();
            this.almendruco++;
        },
        cambiaSolicitud: function(expediente, eleccionasignatura) {
            if (expediente.plazoSolicitud) {
                this.ayudaVisible = false;
                if (!this.solicitudes) {
                    this.iniciaEdicion();
                }
                if (!this.solicitudes.delete(eleccionasignatura)) {
                    this.solicitudes.add(eleccionasignatura)
                } 
                this.almendruco++;   // Fuerza refresco computed
            }
        },
        limpiar: function() {
            this.solicitudes = null;
            this.ayudaVisible = true;
            this.pongo.length = 0;
            this.quito.length = 0;
            this.dejo.length = 0;
            this.errorSolicitud = null;
            this.almendruco++;
        },
        confirmar: function() {
            this.pongo = [...this.solicitudes].filter(x => !this.solicitudActual.has(x)); // A - B
            this.quito = [...this.solicitudActual].filter(x => !this.solicitudes.has(x)); // B - A
            this.dejo =  [...this.solicitudActual].filter(x => this.solicitudes.has(x));  // B ^ A
            // Compruebo si no estamos haciendo nada
            if (this.pongo.length+this.quito.length+this.dejo.length == 0) {
                this.limpiar();
            }
        },
        reconfirmar: function(expediente) {
            this.errorSolicitud = null;
            if (this.$cookies.get(this.$nombreTokenMaestro)) {
                this.errorSolicitud = 'Esta operación está desactivada en modo simulación.';
            }
            else {
                const examenes = [...this.solicitudes].map(a => parseInt(a.examen.id));
                const idSolicitud = expediente.solicitud.length>0 ? parseInt(expediente.solicitud[0].id) : null;
                this.ejecutando++;
                this.$apollo.mutate({
                    mutation: gql`mutation($examenes:[Int]!, $id:Int) {
                        registraSolicitud(examenes:$examenes, idSolicitud:$id) {
                            solicitud {
                                id
                                resuelta
                                fecha
                                urlNotificacion
                                lineasolicitud {
                                    id
                                    examen {
                                        id
                                        estado
                                    }
                                    asignatura {
                                        nombre
                                    }
                                }
                            }
                        }
                    }`,
                    variables: {
                        examenes: examenes,
                        id: idSolicitud,
                    }
                }).then((data) => {
                    if (data.data.registraSolicitud) {
                        expediente.solicitud[0] = data.data.registraSolicitud.solicitud;
                    }
                    else {
                        expediente.solicitud.length = 0;
                    }
                    this.actualizaEstados(expediente);
                    this.limpiar();
                }).catch((error) => {
                    if (error.message.includes('expired')) {
                        // Provoco la recarga de la página para que se limpie todo
                        window.location.reload(false);
                    }
                    else {
                        this.errorSolicitud = 'Se ha producido un error al registrar la solicitud. Por favor, inténtalo más tarde.';
                    }
                }).finally(() => {
                    this.ejecutando--;
                });
            }
        },
        actualizaEstados: function(expediente) {
            // Repasamos todas las asignaturas para encontrar nuevos estados en las líneas de solicitud, 
            // entendiendo que (estando en plazo de solicitud) las que no estén en las líneas de solicitud quedan
            // sin estado.
            if (expediente && expediente.plazoSolicitud) {
                for (const v of expediente.eleccionviaacceso) {
                    for (const p of v.eleccionparte) {
                        for (const a of p.eleccionasignatura) {
                            // Dentro de plazo de solicitud, entendemos que solo existe un estado o nada.
                            a.examen.estado = null;
                            if (expediente.solicitud.length > 0) {
                                for (const n of expediente.solicitud[0].lineasolicitud) {
                                    if (n.examen.id == a.examen.id) {
                                        a.examen.estado = n.examen.estado;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
    }
}
</script>

<style scoped>

.solicitud-modificada {
    animation: blink 1s ease-in infinite;
}

#contenido .tabla-asignaturas a {
    text-decoration: none;
}

tr.fila-modificada {
    background-color: #d5f5e3;
    font-weight: bold;
    font-style: italic;
}
tr.fila-modificada:hover {
    background-color: #d5f5e3;
}
tr.fila-eliminada {
    background-color: #fadbd8;
    font-weight: bold;
    font-style: italic;
}
tr.fila-eliminada:hover {
    background-color: #fadbd8;
}
span.nota-antigua {
    text-decoration: line-through;
    font-size: small;
    padding-right: 5px;
    color: darkgray;
}
@keyframes blink {
  from, to { opacity: 1 }
  50% { opacity: 0 }
}
.cuadro-solicitud {
    margin-bottom: 10px;
    border-width: 1px;
    border-style: solid;
    border-color: coral;
    padding: 10px;
    background-color: antiquewhite;
}

#cabecera-confirmacion {
    background-color: #d9edf7;
}

button:disabled,
button[disabled]{
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

a:disabled,
a[disabled]{
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

div.modal-body ul.sol-ul-add {
    background-color: #d5f5e3;
    list-style-position: inside;
}

div.modal-body ul.sol-ul-delete {
    background-color: #fadbd8;
    list-style-position: inside;
}

div.modal-body ul.sol-ul-equal {
    background-color: antiquewhite;
    list-style-position: inside;
}
</style>