import {createRouter as createVueRouter, createWebHashHistory} from "vue-router"
import Alumno from '@/views/Alumno.vue'
import Login from '@/views/Login.vue'
import Aviso from '@/views/Aviso.vue'

// Genero wrapper para createRouter de manera que pueda recibir la referencia de app y
// tener accesible la globalProperties
const createRoutes = (app) => [
    {
        path: '/',
        name: 'alumno',
        component: Alumno,
    },
    {
        path: '/entrada',
        name: 'login',
        component: Login,
        beforeEnter(to, from, next) {
            if (sessionStorage.getItem(app.config.globalProperties.$nombreToken) !== null) {
                next({name: 'alumno'});
            } else {
                next();
            }
        },
    },
    {
        path: '/aviso',
        name: 'aviso',
        component: Aviso,
    },
]

export const createRouter = (app) => {
  return createVueRouter({
    history: createWebHashHistory(),
    routes: createRoutes(app),
  })
}
