<template>
  <div id="app" @click="dniFallo=mensaje=intentos=null" @keydown="dniFallo=mensaje=intentos=null" >
    <header id="header">
      <div class="navbar-top">
          <div class="navbar-uca">
              <div class="container">
                  <h1><a href="https://www.uca.es/">Universidad<span>de</span>Cádiz</a></h1>
              </div>
          </div>
          <nav id="main-menu" class="navbar navbar-default" :style="'background-color: ' + distintivo + ';'">
              <div class="container">
                  <div class="navbar-header">
                      <a class="navbar-brand logocampus" href="https://www.uca.es/acceso-universidad/">{{titulo}}</a>
                  </div>
              </div>
          <!--/.container-->
          </nav>
          <!--/nav-->
      </div>

      <!-- Componente de sesión -->
      <sesion ref="sesion" 
        @autenticado="autenticado" 
        @logout="cierreSesion(false)" 
        @logout-manual="cierreSesion(true)" 
        @aviso="mostrarMensaje"  
        @aviso-clave="avisoClave" 
        @aviso-fallo="avisoFallo"
      />

    </header>

    <section id="contenido" role="main">
      <div class="container">
          <div class="row">
              <div class="col-md-12 mainContent">
                <div id="error-general" style="display: none;">
                    <br>
                    <div class="publico_info_error_grave">
                      <i class="fa fa-exclamation-triangle"></i> Hay un error que impide continuar. Por favor, vuelve a intentarlo dentro de unos minutos.
                    </div>  
                </div>

                <!-- Mensaje de error -->
                <div v-if="mensaje">
                  <br>
                  <div class="publico_info_error">
                    {{mensaje}}
                    <div v-if="dniFallo!=null && intentos!=null && intentos>=0">
                      <div v-if="intentos==0">
                        No te quedan intentos. Usa el enlace <em>"¿Problemas con la clave de acceso?"</em> para solicitar una nueva clave.
                      </div>
                      <div v-else-if="intentos==1">
                        Te queda un intento antes de que tengas que solicitar una nueva clave.
                      </div>
                      <div v-else>
                        Te quedan {{intentos}} intentos antes de que tengas que solicitar una nueva clave.
                      </div>  
                    </div>
                  </div>
                </div>

                <!-- Inicio Mensaje de operación realizada -->
                <div v-if="dni && mostrarClave">
                  <br>
                  <div class="alert alert-success alert-dismissible fade in text-center" role="alert">
                    {{$mensajeClave}}<br>
                    <b>ANÓTALA EN LUGAR SEGURO</b>.<br>
                    Nueva clave de acceso: <b v-once>{{clave}}</b>
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </div>
                <!-- Fin Mensaje de operación realizada -->

                <!-- Ventana modal de comunicación de clave -->
                <div v-if="dni && clave && mostrarClave">
                    <div id="nuevaclave" class="modal show" tabindex="-1" role="dialog">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="clave=null">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    <h4 class="modal-title" id="myModalLabel3">Nueva clave de acceso</h4>
                                </div>
                                <div class="modal-body text-center">
                                    <p>{{$mensajeClave}}</p>
                                    <h4>
                                        <span class='publicorelevante'>Nueva clave de acceso: {{clave}}</span>
                                    </h4>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-primary" data-dismiss="modal" @click="clave=null">Cerrar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin ventana modal de comunicacion de clave -->

                <!-- Inicio Contenido -->
                <router-view :dni="dni" :sello="sello" :clave="clave" @autenticar="autenticar" @aviso="mostrarMensaje" />
                
                <!-- Fin Contenido -->

              </div>
          </div>
      </div>
    </section>
  </div>
</template>

<script>
import Sesion from '@/components/Sesion.vue'
import gql from 'graphql-tag'
import {errorGeneral} from '@/error.js'

export default {  
  name: 'app',
  components: {
    Sesion,
  },
  errorCaptured: function () {
    errorGeneral();  // Definido en error.js
  }, 
  data() {
    return {
      dni: null,
      sello: null,
      dniFallo: null,
      intentos: null,
      clave: null,
      mostrarClave: false,
      mensaje: null,
      titulo: process.env.VUE_APP_TITULO,   
      distintivo: process.env.VUE_APP_DISTINTIVO,   
    }
  },
  apollo: {
    intentos: {
      query: gql`query($dni:String!) {
        intentos(dni:$dni)
      }`,
      variables() {
        return {
            dni: this.dniFallo,
        }
      },
      fetchPolicy: 'network-only',  // Para que no cachee esta query
      skip() {
        return !this.dniFallo;
      },
    },
  },
  methods: {
    autenticar(credenciales) {
      this.$refs.sesion.entrar(credenciales.dni, credenciales.clave);
    },
    autenticado(sesion) {
      if (this.dni === null || this.dni !== sesion.dni) {
        this.dni = sesion.dni;
        this.sello = sesion.sello;
        this.$router.push({ name: 'alumno' }).catch(function() {});
      }
    },
    cierreSesion(manual) {
      this.dni = null;
      // Puede que un cierre de sesión automatico llegue después del evento de comunicar clave
      // asi que solo borro el aviso de clave si es cierre manual (boton)
      if (manual) {
        this.clave = null;
        this.sello = null;
        this.mostrarClave = false;
      }
      this.$router.push({ name: 'login' }).catch(function() {});
    },
    mostrarMensaje(mensaje) {
      // Incluyo un pequeño retardo para asegurar escribir el mensaje después de cualquier otro evento
      // que pudiera borrarlo.
      setTimeout(() => {this.mensaje = mensaje;}, 100);
    },
    avisoClave(clave) {
      this.clave = clave;
      this.mostrarClave = true;
    },
    avisoFallo(dni) {
      // Rellenamos el DNI fallido para consultar el número de intentos que le quedan
      this.dniFallo = dni;
      this.mostrarMensaje('Usuario o clave incorrectos.')
    },
  },
}
</script>

<style>
</style>
