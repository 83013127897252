<template>
    <div>
        <br>
        <div class="publico_info_1">
            <h4>
                <i class="fa fa-info-circle"></i> En este momento no hay calificaciones disponibles para su consulta pública.
            </h4>
        </div>
    </div>  
</template>

<script>
export default {
    name: 'aviso',
    mounted() {
      fetch(process.env.VUE_APP_API_URL + '/init/')
        .then(response => {
            return response.json();
        })
        .then(data => {
            if (!data.cerrado) {
                this.$router.push({ name: 'login' }).catch(function() {});
            }    
        });
    },
}
</script>

<style>

</style>