<template>
    <div>
        <!-- Inicio Contenido -->

        <div class="col-xs-12">
            <!-- Indicador de carga -->
            <div v-if="cargando>0">
                <h3 style="display: block; text-align: center; margin-bottom: 30px; margin-top: 50px;">Procesando...</h3>
                <div class="spinner"></div>
            </div>
            <div class="uca-form uca-login-form">
                <div id="uca-form-content" class="uca-form-content row">
                    <div class="col-md-offset-2 col-md-8 col-sm-offset-2 col-sm-8 col-xs-offset-1 col-xs-10 text-center">
                        <div v-if="tokenMaestro" @keyup="pulsaEnterSimular" >
                            <h2 class="form-title">Simulador</h2>
                            <div class="form-group row">
                                <label for="dni" class="col-xs-5 col-form-label text-right">Documento:</label>
                                <div class="col-sm-5 col-xs-7 text-left">
                                    <input type="text" name="dni" v-model="dni" placeholder="Documento">
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="actual" class="col-xs-5 col-form-label text-right">Usar fecha:</label>
                                <div class="col-sm-5 col-xs-7 text-left">
                                    <input id="radio-actual" type="radio" value="si" v-model="actual"> Actual
                                    <input id="radio-simulada" type="radio" value="no" v-model="actual"> Simulada                                        
                                </div>
                            </div>

                            <div v-if="actual!='si'" class="form-group row">
                                <label for="fecha" class="col-xs-5 col-form-label text-right">Fecha:</label>
                                <div class="col-sm-5 col-xs-7 text-left">
                                    <VueDatePicker :disabled="actual=='si'"
                                                   v-model="fecha" locale="es"
                                                   format="dd-MM-yyyy HH:mm:ss"
                                                   text-input
                                                   select-text="Seleccionar" cancel-text="Cancelar" />
                                </div>
                            </div>

                            <!-- BOTONERA -->
                            <div class="form-group row">
                                <div class="col-md-12 col-sm-12 col-xs-12 text-center">
                                    <button id="boton-simular" class="btn btn-primary" @click.prevent="simular" aria-label="Simular">Simular</button> &nbsp;
                                    <button id="boton-modo-normal" class="btn btn-secundary" @click.prevent="modoNormal" aria-label="Modo normal">Modo normal</button>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <div v-if="modoLogin" @keyup="pulsaEnterLogin" >
                                <h2 class="form-title">Acceso privado</h2>

                                <div class="form-info">Utiliza tu documento (NIF/NIE o Pasaporte), 
                                    junto con la clave que te hemos proporcionado.</div>
                            
                                <!-- CAMPOS -->
                                <div class="form-group row">
                                    <label for="dni" class="col-xs-5 col-form-label text-right">Documento:</label>
                                    <div class="col-sm-5 col-xs-7 text-left">
                                        <input type="text" name="dni" id="dni" aria-label="Documento" v-model="dni" placeholder="Documento">
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label for="clave" class="col-xs-5 col-form-label text-right">Clave:</label>
                                    <div class="col-sm-5 col-xs-7 text-left">
                                        <input type="password" name="clave" id="clave" aria-label="Clave" v-model="clave" placeholder="Clave">
                                    </div>
                                </div>

                                <!-- BOTONERA -->
                                <div class="form-group row">
                                    <div class="col-md-12 col-sm-12 col-xs-12 text-center">
                                        <button id="boton-acceder" class="btn btn-primary" @click.prevent="autenticar" aria-label="Acceder">Acceder</button>
                                    </div>

                                    <div class="col-sm-offset-2 col-sm-8 col-xs-offset-0 col-xs-12 form-info-xtra text-center">
                                        <a id="enlace-problemas" href="#" class="btn btn-xtra-info" @click.prevent="modoLogin = false">
                                            <em>¿Problemas con la clave de acceso?</em>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div v-else @keyup="pulsaEnterSolicitar" > 
                                <h2 class="form-title">Solicitud de nueva clave</h2>

                                <div class="form-info">Puedes solicitar una nueva clave por correo electrónico y/o SMS. 
                                    Utiliza los mismos datos de contacto que tienes registrados.</div>
                            
                                <!-- CAMPOS -->
                                <div class="form-group row">
                                    <label for="dni" class="col-xs-5 col-form-label text-right">Documento:</label>
                                    <div class="col-sm-5 col-xs-7 text-left">
                                        <input type="text" name="dni" id="dni" aria-label="Documento" v-model="dni">
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label for="email" class="col-xs-5 col-form-label text-right">Email:</label>
                                    <div class="col-sm-5 col-xs-7 text-left">
                                        <input type="text" name="email" id="email" aria-label="Email" v-model="email">
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label for="telefono" class="col-xs-5 col-form-label text-right">Teléfono móvil:</label>
                                    <div class="col-sm-5 col-xs-7 text-left">
                                        <input type="text" name="telefono" id="telefono" aria-label="Teléfono móvil" v-model="telefono">
                                    </div>
                                </div>


                                <!-- BOTONERA -->
                                <div class="form-group row">
                                    <div class="col-md-12 col-sm-12 col-xs-12 text-center">
                                        <button id="boton-solicitar" class="btn btn-info" aria-label="Solicitar clave" @click.prevent="solicitar">Solicitar clave</button> &nbsp;
                                        <button class="btn btn-primary" aria-label="Cancelar" @click.prevent="modoLogin = true" >Cancelar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Fin Contenido -->
    </div>
</template>

<script>
import gql from 'graphql-tag'
import sha512 from 'js-sha512'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

export default {
    name: 'login',
    components: {
        VueDatePicker,
    },
    data() {
        return {
            modoLogin: true,
            cargando: 0,
            dni: '',
            clave: '',
            email: '',
            telefono: '',
            tokenMaestro: '',
            fecha: new Date(),
            actual: 'si',
        }
    },
    computed: {
        selloTiempo: function() {
            return this.actual=='si'? 0 : Math.floor(this.fecha.getTime()/1000);
        }
    },
    created() {
        this.tokenMaestro = this.$cookies.get(this.$nombreTokenMaestro);
    },
    methods: {
        autenticar() {
            if (this.dni !== '' && this.clave !== '') {
                this.$emit('autenticar', 
                    {
                        dni: this.dni,
                        clave: this.clave,
                    }
                );
                this.dni = '';
                this.clave = '';
            }
            else {
                this.$emit('aviso', 'Debes introducir documento y clave.');
            }
        },
        solicitar() {
            if (this.dni !== '' && (this.email !== '' || this.telefono !== '')) {
                this.cargando++;
                this.$apollo.mutate({
                    mutation: gql`mutation($dni: String!, $email: String, $telefono: String) {
                        pedirActivacion(dni:$dni, email: $email, telefono: $telefono) {
  	                        resultado
                            mensaje
                        }
                    }`,
                    variables: {
                        dni: this.dni,
                        email: this.email,
                        telefono: this.telefono,
                    },
                }).then((data) => {
                    if (data.data.pedirActivacion.resultado) {
                        this.$emit('aviso', data.data.pedirActivacion.mensaje);
                        // Respetamos formularios excepto clave y volvemos a la página de login
                        this.clave = '';
                        this.modoLogin = true;
                    }
                    else {
                        this.$emit('aviso', data.data.pedirActivacion.mensaje);
                    }
                }).catch(() => {
                    this.$emit('aviso', 'Ha habido un error al solicitar la clave. Inténtalo más tarde.');
                }).finally(() => {
                    this.cargando--;
                });
            }
        },
        modoNormal() {
            this.tokenMaestro = null;
            this.$cookies.remove(this.$nombreTokenMaestro);
        },
        simular() {
            this.clave = this.tokenMaestro + '/' + this.selloTiempo + '/' + sha512(process.env.VUE_APP_CLAVE_MAESTRA + this.dni);
            this.autenticar();
        },
        pulsaEnterSimular: function(ev) {
            if (ev.key == 'Enter') {
                this.simular();
            }
        },
        pulsaEnterLogin: function(ev) {
            if (ev.key == 'Enter') {
                this.autenticar();
            }
        },
        pulsaEnterSolicitar: function(ev) {
            if (ev.key == 'Enter') {
                this.solicitar();
            }
        },
    }
}
</script>

<style scoped>
</style>