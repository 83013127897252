import { createApp } from 'vue'
import App from '@/App.vue'
import {createRouter} from '@/router'
import { createApolloProvider } from '@vue/apollo-option'
import ApolloClient from 'apollo-boost'
import VueCookies from 'vue-cookies'
import {errorGeneral} from '@/error.js'

// Control de cierre por superflag
// Lo hago fuera del entorno Vue para asegurarme de que
// se establece la cookie CSRF antes de cualquier otra petición
if (window.location.pathname != '/#/aviso') {
  const httpPet = new XMLHttpRequest();
  httpPet.open('GET', process.env.VUE_APP_API_URL + '/init/');
  httpPet.withCredentials = true;
  httpPet.send();
  httpPet.onreadystatechange = function() {
      if (this.readyState == 4 && this.status == 200 && JSON.parse(httpPet.responseText).cerrado) {
          window.location.replace('/#/aviso');
      }
  }
}

window.onerror = errorGeneral; 

const app = createApp(App);
app.use(VueCookies);

app.config.globalProperties.$nombreToken = 'npa-auth-token';
app.config.globalProperties.$nombreTokenMaestro = 'npa-auth-mtoken';
app.config.globalProperties.$mensajeClave = `El sistema le ha generado una NUEVA CLAVE que le dará acceso a consultar, 
SIEMPRE QUE ESTÉ ACTIVO, nuevamente las calificaciones, 
a reclamar la calificación de alguna asignatura 
y también a la descarga de la tarjeta de calificaciones.`;

// Declaro el apollo client después de iniciar la aplicación para poder tener acceso al objeto app
const apolloClient = new ApolloClient({
  uri: process.env.VUE_APP_API_URL,
  request: async (operation) => {
    if (sessionStorage.getItem(app.config.globalProperties.$nombreToken)) {
      operation.setContext({
        headers: {
          'Authorization': 'JWT ' + sessionStorage.getItem(app.config.globalProperties.$nombreToken),
          'X-CSRFTOKEN': app.$cookies.get('csrftoken'),
        }
      });
    }
    else {
      operation.setContext({
        headers: {
          'X-CSRFTOKEN': app.$cookies.get('csrftoken'),
        },
      });
    }
  }
});

const apolloProvider = createApolloProvider({
  defaultClient: apolloClient,
});

app.use(apolloProvider);
app.use(createRouter(app));
app.mount('#app');
